<template>
  <div class="exam5s-list">
    <div style="margin-bottom: 10px">
      <img
        style="width: 15px"
        src="../../assets/images/goback.png"
        alt
        @click="toBack"
      />
      <span style="margin-right: 15px" @click="toBack">&nbsp;返回5s检查表</span>
    </div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['待审核', '已审核']"
        :key="index"
        :class="{ activeColor: index == tabIndex }"
        @click="toChange(index)"
        >{{ item }}</span
      >
    </div>
    <div class="mid_box" v-if="isCheck">
      <el-button type="primary" class="edit" @click="addQuestion">
        新增提问
        <i class="el-icon-circle-plus-outline el-icon--right"></i>
      </el-button>
      <span style="margin-top: 5px; margin-left: 10px">
        <img
          src="../../assets/images/Examine_img/del1.png"
          alt
          @click="delItem"
        />
      </span>
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="getPdf()"
        >打印检查表</el-button
      >
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="exportExcel"
        >导出检查表</el-button
      >
    </div>
    <div class="toSave1" @click="toSave" v-if="!isCheck">
      <img
        style="width: 15px"
        src="../../assets/images/Examine_img/sava.png"
        alt
      />
      <span>完成检查</span>
    </div>
    <div class="_TableBox">
      <el-table
        v-show="isCheck"
        :data="
          tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        style="width: 100%"
        :height="elementHeight"
        border
        stripe
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="50"
          :index="(e) => (currentPage - 1) * pageSize + e + 1"
        />
        <el-table-column prop="fileName" label="文件名称"> </el-table-column>
        <el-table-column prop="question" label="问题">
          <template slot-scope="scope">
            <div>{{ scope.row.question }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="question" label="扣分">
          <template slot-scope="scope">
            <span
              style="
                display: inline-block;
                width: 50px;
                border: 1px solid #20c166;
                background: #e7f5ed;
                border-radius: 50px;
                padding: 3px 0;
              "
            >
              {{ scope.row.auditResult ? scope.row.auditResult : 0 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="question" label="单项分值">
          <template slot-scope="scope">
            <div>0~{{ scope.row.violatedTerms }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="imagesCount" label="图片">
          <template slot-scope="scope">
            <span class="imagesCount">共 {{ scope.row.imagesCount }} 张</span>
          </template>
        </el-table-column>
        <el-table-column prop="imagesCount" label="扣分">
          <template slot-scope="scope">
            <el-button
              class="button"
              plain
              style="color: #1361ff; border-color: #1361ff"
              @click="changeResult(scope.row)"
              >点击扣分</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="查看答案">
          <template slot-scope="scope">
            <el-button
              style="color: #1361ff; border-color: #1361ff"
              class="button"
              plain
              @click="isChoose(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-show="!isCheck"
        ref="multipleTable"
        :data="
          tableData1.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        "
        style="width: 100%"
        :height="elementHeight"
        stripe
        border
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        @cell-dblclick="handleCell"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="50"
          :index="(e) => (currentPage - 1) * pageSize + e + 1"
        />
        <el-table-column prop="fileName" label="文件名称"> </el-table-column>
        <el-table-column prop="question" label="问题">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.question }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="question" label="扣分">
          <template slot-scope="scope">
            <span
              style="
                display: inline-block;
                width: 50px;
                border: 1px solid #20c166;
                background: #e7f5ed;
                border-radius: 50px;
                padding: 3px 0;
              "
            >
              {{ scope.row.auditResult ? scope.row.auditResult : 0 }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="auditResult"
          label="单项分值"
          align="center"
        >
          <template slot-scope="scope">
            <span> 0~{{ scope.row.violatedTerms }} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="190"
        >
          <template slot-scope="scope">
            <div class="img_box" @click="openImg(scope.row)">
              <img src="../../assets/images/Examine_img/add.png" alt />
              <span v-if="scope.row.imagesCount == 0">请添加图片</span>
              <span v-else style="margin-right: 50%">{{
                scope.row.imagesCount
              }}</span>
              <img src="../../assets/images/Examine_img/picture.png" alt />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lawAsk" label="审核意见（双击列表）" width="250">
          <template slot-scope="scope">
            <input
              v-if="
                auditId === scope.row.id &&
                columnID === scope.column.id &&
                status != 2
              "
              type="text"
              @focus="compare(scope.row.lawAsk)"
              @blur="Updata(scope.row, scope.row.lawAsk)"
              v-model="scope.row.lawAsk"
            />
            <span v-else>{{ scope.row.lawAsk }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="imagesCount" label="扣分">
          <template slot-scope="scope">
            <el-button
              class="button"
              plain
              style="color: #1361ff; border-color: #1361ff"
              @click="changeResult(scope.row)"
              >点击扣分</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="查看答案" prop="answer" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              style="color: #1361ff; border-color: #1361ff"
              class="button"
              plain
              @click="isChoose(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="isCheck ? tableData.length : tableData1.length"
        class="_Pagination"
      ></el-pagination>
    </div>

    <el-dialog title="新增提问" :visible.sync="dialogVisible" width="500px">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin-right: 10px; width: 65px">问题描述</div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="form.question"
            maxlength="500"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
    >
      <div v-show="status != 2">上传图片</div>
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
        v-show="status != 2"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>

        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex && status != 2"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">答案：{{ answer }}</div>
      <div style="font-size: 18px">图片：</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisibleAsk = false"
          >确 定</el-button
        >
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="扣分" :visible.sync="dialogVisibleresult" width="35%">
      <el-table-column prop="question" label="扣分">
          <template slot-scope="scope">
            <span
              style="
                display: inline-block;
                width: 50px;
                border: 1px solid #20c166;
                background: #e7f5ed;
                border-radius: 50px;
                padding: 3px 0;
              "
            >
              {{ scope.row.auditResult ? scope.row.auditResult : 0 }}
            </span>
          </template>
        </el-table-column>
      <div class="input-number">
        <span>单项分值</span>
        <span>0~{{ form.violatedTerms }}</span>
      </div>
      <el-form label-width="80px">
        <el-form-item label="扣分">
          <el-input
            v-model="form.auditResult"
            @input="handleInput"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核结果">
          <el-input v-model="form.lawAsk"></el-input>
        </el-form-item>
        <el-form-item label="现场证据">
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div style="color: rgb(201, 200, 200)">点击上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px; margin-right: 3px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            ></el-image>
            <i
              class="el-icon-delete-solid"
              v-show="index === Curindex"
              @click="delImg(item)"
            ></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-table
      id="pdfDom"
      :data="tableAllData"
      style="width: 100%; position: absolute; left: -100%; z-index: -999"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="fileName" label="文件名称"> </el-table-column>
      <el-table-column prop="question" label="问题"></el-table-column>
      <el-table-column
        prop="auditResult"
        label="扣分"
        align="center"
      >
        <template slot-scope="scope">
          <div @click.prevent="changeResult(scope.row)">
            <span style="color: rgb(0, 132, 255)">{{
              scope.row.auditResult ? scope.row.auditResult : 0
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="question" label="单项分值">
          <template slot-scope="scope">
            <div>0~{{ scope.row.violatedTerms }}</div>
          </template>
        </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="190"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imagesCount > 0"
              style="margin-right: 50%"
              >{{ scope.row.imagesCount }}</span
            >
            <img src="../../assets/images/Examine_img/picture.png" alt />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="answer" label="答案"></el-table-column>
      <el-table-column prop="lawAsk" label="审核意见">
        <template slot-scope="scope">
          <span>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
  
  <script>
import { get, post, put, deleteMethod, baseURL } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";
import { exportExcel } from "../../modules/ExportExcel";
export default {
  data() {
    return {
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      BUrl: baseURL[process.env.NODE_ENV + ""],
      htmlTitle: "5s检查表",
      elementHeight: 0,
      isCheck: true,
      tabIndex: 0,
      tableData: [], // 待审核
      tableData1: [], // 已审核
      tableAllData: [],
      tableRowIndex: null,
      PlanId: this.$route.query.PlanId,
      status: this.$route.query.status,
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false, // 新增
      dialogVisibleresult: false, // 审核
      dialogVisibleimg: false, // 上传图片
      dialogVisibleAsk: false, // 查看答案
      form: {
        question: "",
      },
      srcList: [],
      Cid: null,
      Curindex: null,
      auditResult: null,
      auditId: null,
      curlawask: "",
      columnID: null,
      answer: "",
      isshowAns: false,
      number: 0,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 10 + 20 + 64 + 40 + 70); //70是div底部距离底部的高度
      });
    },
    toChange(i) {
      this.tabIndex = i;
      this.isCheck = this.tabIndex == 0 ? true : false;
      this.tableRowIndex = null;
    },
    getList() {
      get("/api/Audit5SList/GetByAuditId?AuditId=" + this.PlanId).then(
        (res) => {
          this.tableData = res.data.filter((item) => item.auditResult == null); // 待审核
          this.tableData1 = res.data.filter((item) => item.auditResult != null); // 已审核
          this.tableAllData = res.data; // 全部
          this.tableRowIndex = null;
        }
      );
    },
    // 导出
    exportExcel() {
      this.isshowAns = true;
      this.$nextTick(() => {
        exportExcel("#pdfDom", "5s检查表.xlsx");
        this.isshowAns = false;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    rowClick(row) {
      this.form = row;
      this.tableRowIndex = row.index;
      this.auditId = row.id;
    },
    changeCheck(row) {
      this.rowClick(row);
    },
    handleCell(row, column) {
      this.columnID = column.id;
    },
    compare(e) {
      this.curlawask = e;
    },
    Updata(row, e) {
      this.columnID = "";
      if (this.curlawask === e) return;
      put("/api/SupplierCheckplanlist", row).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "更改成功",
          });
        } else {
          this.$message.error(res.message);
          row.lawAsk = "";
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    // 新增提问
    addQuestion() {
      if (this.tableData1.length != 0) {
        this.$message({
          type: "warning",
          message: "已有题目审核过,不可重新抽题",
        });
        return;
      }
      this.dialogVisible = true;
      this.form.question = "";
      this.form.id = 0;
    },
    // 确定提问
    toAddASK() {
      this.form.auditId = this.PlanId;
      post("/api/Audit5SList", this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success("新增成功");
          this.dialogVisible = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除
    delItem() {
      if (!this.form.id) {
        this.$message.warning("请选择要删除的题目");
        return;
      }
      this.$confirm("此操作将永久删除该题目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/Audit5SList?Id=" + this.form.id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 审核
    changeResult(row) {
      if (this.status == 2) {
        this.$message({
          type: "warning",
          message: "已完成检查，禁止修改",
        });
        return;
      }
      this.dialogVisibleresult = true;
      this.Cid = row.id;
      this.getImage();
      this.form = row;
    },
    // 审核结果
    handleInput(e) {
      if (e > this.form.violatedTerms) {
        this.$message.warning("输入分值已超过最大值");
        this.form.auditResult = 0;
      }
    },
    toSure() {
      put("/api/Audit5SList", this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success("保存成功");
          this.dialogVisibleresult = false;
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 完成检查
    toSave() {
      if (this.tableData.length != 0) {
        this.$message.warning("请完成审核");
        return;
      }
      if (this.status == 2) {
        this.$message.warning("检查已完成,请勿重复操作");
        return;
      }
      put("/api/Audit5S", JSON.parse(this.$route.query.planItem)).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("检查完成");
            this.status = 2;
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    isChoose(e) {
      this.srcList = [];
      this.dialogVisibleAsk = true;
      this.answer = e.answer;
      if (e.imagesCount != 0) {
        this.Cid = e.id;
        // var imgdata = e.questionImageAddr.split(",");
        // imgdata.forEach((e) => {
        //   this.srcList.push(this.BUrl + "/uploads/" + e);
        // });
        this.getImage();
      }
    },
    openImg(e) {
      this.Cid = e.id;
      this.dialogVisibleimg = true;
      this.getImage();
    },
    toPostImg() {
      this.dialogVisibleimg = false;
      this.$refs.upload.clearFiles();
      this.getList();
    },
    getImage() {
      this.srcList = [];
      get("/api/Audit5SListImage/" + this.Cid).then((res) => {
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true;
    },
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            this.uploadFileReq(e.file);
          } else {
            this.uploadFileReq(result);
          }
        });
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadImg", formData)
        .then((res) => {
          this.$refs.upload.clearFiles();
          if (res.code == 200) {
            var data = {
              AuditListId: this.Cid,
              ImageRemark: "",
              ImageAddress: this.BUrl + "/uploads/" + res.value,
              saveUserId: this.$store.state.userInfo.id,
            };
            post("/api/Audit5SListImage", {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles();
              this.form.imagesCount = this.form.imagesCount + 1;
              this.getImage();
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    overimg(index) {
      this.Curindex = index;
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod("/api/Audit5SListImage?Id=" + e.id).then((res) => {
                if (res.code == 200) {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  item.imagesCount = item.imagesCount - 1;
                  this.getImage();
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.tableRowIndex = null;
      this.form.id = null;
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.tableRowIndex = null;
      this.form.id = null;
    },
  },
};
</script>
  
  <style scoped lang="less">
@import "../../assets/style/Examine_CSS.css";
.exam5s-list {
  .input-number {
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
    span:nth-of-type(1) {
      width: 80px;
      text-align: center;
      align-content: center;
    }
  }
  .el-input-number {
    line-height: 28px !important;
  }
  .el-form-item__label {
    padding: 0;
  }
  .img_box {
    padding-left: 20px;
  }
  .imagesCount {
    border: 1px solid #d1cfcf;
    width: 60px;
    display: inline-block;
    padding: 2px 0;
  }
  .violatedTerms {
    border: 1px solid;
    border-radius: 50px;
  }
  .el-form-item {
    margin-bottom: 0 !important;
    .el-input {
      width: 200px !important;
    }
  }
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  /deep/ input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
  